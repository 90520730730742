import logo from './logo-cropped.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Coming soon.. go <a href="https://twitter.com/KDTrey5/status/1451242907361583122" target="_blank" rel="noreferrer" className="App-link">Nets!</a></p>
      </header>
    </div>
  );
}

export default App;
